import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import classnames from 'classnames';
import { compact, get } from 'lodash';
import { graphql, PageProps } from 'gatsby';

import SEO from '../../components/SEO/SEO';
import CareersCard from '../../../src/components/CareersCard';
import Divider from '../../../src/components/Divider';
import Breadcrumb from '../../../src/components/Breadcrumb';
import LayoutWrapper, { intlWrapperHOC, v4tov3HOC } from '../../../src/components/LayoutWrapper';
import { StrapiImageAndTextComponent } from '../../../src/components/StrapiComponents/StrapiImageAndTextComponent';
import { StrapiFooterCtaComponent } from '../../components/StrapiComponents/StrapiFooterCta';
import { Scalars, SchoolCareerPostsQuery } from '../../../graphql.schema';
import { GlobalPageContext } from '../../types';
import translations from '../../translations/en.json';
import { v4tov3base } from '../../lib/mapV4toV3';

import * as styles from '../careers/careers-index.module.scss';

type PageContextType = GlobalPageContext & {
  documentId: Scalars['ID'];
};

const SchoolCareersIndex: React.FC<PageProps<SchoolCareerPostsQuery, PageContextType>> = ({ data, pageContext }) => {
  const { websiteLocale: websiteLocaleV3 } = pageContext;
  const websiteLocale = v4tov3base({ id: websiteLocaleV3?.documentId, ...websiteLocaleV3 });
  const { careersCta } = data.strapi;
  const pages = get(data, 'strapi.pages', []);

  // hacking our way a bit here since publicationState seems to be an args on collections only in strapi v4
  // @ts-ignore
  const school = data.strapi.schools[0] as SchoolCareerPostsQuery['strapi']['school'] | undefined;
  const intl = useIntl();
  const showFooterCta = pages[0]?.pageSettings?.showFooterCta ?? false;
  const footerCta = pages[0]?.pageSettings?.customFooterCta?.documentId
      ? pages[0]?.pageSettings?.customFooterCta
      : websiteLocale.footerCta;
  return (
    <LayoutWrapper {...pageContext}>
      <SEO 
      title={intl.formatMessage(
          { id: 'seo.school-careers.title', defaultMessage: translations['seo.school-careers.title'] },
          {
            name: school?.name || '',
            siteTitle: pageContext.websiteLocale.name,
          },
        )}
        description={intl.formatMessage(
          { id: 'seo.school-careers.description', defaultMessage: translations['seo.school-careers.description'] },
          { name: school?.name || '',
            siteTitle: pageContext.websiteLocale.name,
          },
        )}
        avoidIndexing={pages[0]?.pageSettings?.avoidIndexing ?? false}
        image={pages[0]?.seo?.metaImage?.url}
        canonicalURL={pages[0]?.seo?.canonicalURL}
        keywords={pages[0]?.seo?.keywords}
        metaSocial={pages[0]?.seo?.metaSocial}
        structuredData={pages[0]?.seo?.structuredData}
        currentPage={pageContext}
      />
      <div className={classnames('container', styles.root)}>
        <Breadcrumb
          pageContext={pageContext}
          lastCrumb={intl.formatMessage({ id: 'careersIndexHeading', defaultMessage: 'Careers' })}
        />
        <h1 className="title color-a">
          <FormattedMessage id="careersAtText" defaultMessage="Careers at" /> {school?.name}
        </h1>
        <Divider paddingTop="75px" marginBottom="25px" />
        {!compact(school?.careerPosts).length ? (
          <div className={classnames('column is-5 p-0 mt-10', styles.noVacancies)}>
            <h4 className="title is-4">
              <FormattedMessage id="noCareersIndexHeading" defaultMessage="No current career opportunities." />
            </h4>
            <p>
              <FormattedMessage
                id="noPromotionsIndexDescription"
                defaultMessage="We’re not currently hiring for any positions, please check back soon."
              />
            </p>
          </div>
        ) : null}
        <div className={styles.gridWrapper}>
          {compact(school?.careerPosts).map(({ title, slug, short, jobType, city }) => {
            if (!slug) return null;
            return (
              <CareersCard
                linkText={intl.formatMessage({ id: 'careersCardText', defaultMessage: 'View Opportunity' })}
                slugBase={pageContext.pathPrefixes.careers}
                jobType={jobType?.name ?? ''}
                city={city?.name ?? ''}
                key={`career-${title}`}
                title={title ?? 'N/A'}
                short={short ?? ''}
                slug={slug}
              />
            );
          })}
        </div>
      </div>
      {careersCta?.ctaPanel && <StrapiImageAndTextComponent {...careersCta?.ctaPanel} />}
      {footerCta && showFooterCta && <StrapiFooterCtaComponent {...footerCta} />}
    </LayoutWrapper>
  );
};

export default intlWrapperHOC(v4tov3HOC(SchoolCareersIndex));

export const query = graphql`
query SchoolCareerPosts($documentId: ID!, $hasCareersCta: Boolean!, $careersCtaId: ID!, $locale: Strapi_I18NLocaleCode,,$websiteLocaleId: ID!) {
  strapi {
    careersCta(documentId: $careersCtaId) @include(if: $hasCareersCta) {
      data {
        documentId
        attributes {
          ctaPanel: cta_panel {
            ...ImageAndTextComponent
          }
        }
      }
    }
    schools(filters: {documentId: {eq: $documentId}}, locale: $locale) {
      data {
        documentId
        attributes {
          name
          careerPosts: career_posts {
            data {
              documentId
              attributes {
                title
                short
                slug
                localizations {
                  data {
                    attributes {
                      locale
                    }
                  }
                }
                jobType: job_type {
                  data {
                    documentId
                    attributes {
                      name
                    }
                  }
                }
                city {
                  data {
                    documentId
                    attributes {
                      name
                    }
                  }
                }
              }
            }
          }
          seo {
            ...SEOComponent
          }
        }
      }
    }
    pages(
      filters: {website_locale: {documentId: {eq: $websiteLocaleId}}, index_page: {eq: "Schools"}}
      locale: $locale
    ) {
      data {
        documentId
        attributes {
          title
          slug
          components {
            ...PageComponents
          }
          page_settings {
            ...PageSettings
          }
          seo {
            ...SEOComponent
          }
        }
      }
    }
  }
}
`;
